import cookies from 'js-cookie'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


/* Falcon configs */
export const version = '4.1.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'inverted'
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };

/* Notaguess configs */
export function authorizeUrl() {
  return apiServerUrl() + "/v0/oauth/local/login"
}

export function authorizeSquareUrl() {
  return apiServerUrl() + "/v0/user/square/authorize"
}

export function apiServerUrl() {
  if (process.env.REACT_APP_NOTAGUESS_DEPLOY.trim() === 'local') {
    return "http://" + hostDomain()
  } else if (process.env.REACT_APP_NOTAGUESS_DEPLOY.trim() === 'sandbox') {
    return "https://sandbox.api." + hostDomain()
  }
  return "https://api." + hostDomain()
}

export function hostDomain() {
  return process.env.REACT_APP_NOTAGUESS_API_HOST.trim();
}

export function cookieDomain() {
  if (process.env.REACT_APP_NOTAGUESS_DEPLOY.trim() === 'local') {
    const url = new URL(hostDomain());
    return url.hostname;
  } else if (process.env.REACT_APP_NOTAGUESS_DEPLOY.trim() === 'sandbox') {
    return hostDomain()
  }
  return "." + hostDomain()
}

export function isAuthed() {
  let token = cookies.get("notaguess_token");
  return (token !== undefined);
}

export function triggerLogout() {
  cookies.remove("notaguess_token", {
    path: '/',
    domain: cookieDomain(),
    expires: new Date(0).toUTCString()
  });

  cookies.remove("notaguess_user_id", {
    path: '/',
    domain: cookieDomain(),
    expires: new Date(0).toUTCString()
  });

  cookies.remove("notaguess_escalate", {
    path: '/',
    domain: cookieDomain(),
    expires: new Date(0).toUTCString()
  });

  window.location = "/";
}

export function triggerLogin() {
  window.location.replace(authorizeUrl());
}

export function convertDateStringToJsCompliant(dateString) {
  if (dateString) {
    return dateString.replace(/-/g, '/');
  }
  return null;
}

export function userTrackPageViews(userId) {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname + location.search;
    const encodedPath = encodeURIComponent(path);

    window.gtag('event', 'page_view', {
      page_path: path,
      user_id: userId,
      custom_path: path,
      custom_user_id: userId,
    });

    let escalateToken = cookies.get("notaguess_escalate");
    if (escalateToken !== "1") {
      fetch(`${apiServerUrl()}/v0/user/event?event=page_view&value=${encodedPath}`, {
        'credentials': 'include',
      })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
        return null;
      })
    }
  }, [location, userId]);
}

